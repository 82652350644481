<nz-sider nzTheme="light" nzCollapsible [nzCollapsed]="isCollapsed" [nzTrigger]="null" class="non-selectable">
  @defer {
    <div class="venue">
      <div class="venue__logo">
        @if (selectedVenue(); as selectedVenue) {
          @if (elementLoaded) {
            <img
              class="logo"
              [alt]="'venue-logo' | translate"
              [ngSrc]="selectedVenue.logoUrl"
              fill
              priority
              (error)="elementLoaded = false" />
          } @else {
            <nz-skeleton-element nzType="image" [nzActive]="true" [title]="'venue-logo' | translate"></nz-skeleton-element>
          }
        }
      </div>
      <nz-select
        class="selected-venue"
        [ngModel]="selectedVenue()"
        [compareWith]="compareModelsFn"
        (ngModelChange)="valueChanged($event)"
        [nzPlaceHolder]="'venue-select' | translate">
        @for (venue of userVenues(); track venue._id) {
          <nz-option [nzValue]="venue" [nzLabel]="venue.name"></nz-option>
        }
      </nz-select>
    </div>
  }

  @defer {
    <ul class="menu-container" nz-menu nzTheme="light" nzMode="inline">
      <li nz-submenu nz-tooltip nzTooltipPlacement="right" [(nzOpen)]="openMap.main" (nzOpenChange)="openHandler('main')">
        <div title class="ant-menu-submenu-title-custom">
          <span class="icon" nz-icon nzType="home" nzTheme="fill"></span>
          <span>{{ 'venue-menu' | translate }}</span>
        </div>
        <ul>
          @for (menuItem of mainLinks; track menuItem.defaultRoute) {
            @if (menuItem.sublinks?.length) {
              <li nz-submenu [nzOpen]="sublinkContainsCurrentUrl(menuItem.sublinks)">
                <a title>
                  @switch (menuItem.iconType) {
                    @case (iconTypeEnum.WAYTR) {
                      <span class="icon" [title]="menuItem.title">
                        <waytr-icon
                          [name]="menuItem.icon || 'food_menu'"
                          [title]="'icons.' + (menuItem.icon || 'food_menu') | translate"></waytr-icon>
                      </span>
                    }
                    @case (iconTypeEnum.NZ) {
                      <span
                        class="icon"
                        nz-icon
                        [nzType]="menuItem.icon || 'home'"
                        nzTheme="fill"
                        [title]="'icons.' + (menuItem.icon || 'home') | translate"></span>
                    }
                  }
                  <span class="page-title">{{ menuItem.translatePath | translate }}</span>
                </a>
                <ul>
                  @for (subLink of menuItem.sublinks; track $index) {
                    <li nz-menu-item nzMatchRouter>
                      <a [routerLink]="subLink.route" class="non-selectable" routerLinkActive="link-active">
                        @switch (subLink.iconType) {
                          @case (iconTypeEnum.WAYTR) {
                            <span class="icon" [title]="subLink.title">
                              <waytr-icon
                                [name]="subLink.icon || 'food_menu'"
                                [title]="'icons.' + (menuItem.icon || 'food_menu') | translate"></waytr-icon>
                            </span>
                          }
                          @case (iconTypeEnum.NZ) {
                            <span
                              class="icon"
                              nz-icon
                              [nzType]="subLink.icon || 'home'"
                              nzTheme="fill"
                              [title]="'icons.' + (menuItem.icon || 'home') | translate"></span>
                          }
                        }
                        <span class="page-title">{{ subLink.translatePath | translate }}</span>
                      </a>
                    </li>
                  }
                </ul>
              </li>
            } @else {
              <li nz-menu-item nzMatchRouter>
                <a [routerLink]="menuItem.route" class="non-selectable" routerLinkActive="link-active">
                  @switch (menuItem.iconType) {
                    @case (iconTypeEnum.WAYTR) {
                      <span class="icon" [title]="menuItem.title">
                        <waytr-icon
                          [name]="menuItem.icon || 'food_menu'"
                          [title]="'icons.' + (menuItem.icon || 'food_menu') | translate"></waytr-icon>
                      </span>
                    }
                    @case (iconTypeEnum.NZ) {
                      <span
                        class="icon"
                        nz-icon
                        [nzType]="menuItem.icon || 'home'"
                        nzTheme="fill"
                        [title]="'icons.' + (menuItem.icon || 'home') | translate"></span>
                    }
                  }
                  <span class="page-title">{{ menuItem.translatePath | translate }}</span>
                </a>
              </li>
            }
          }
        </ul>
      </li>
      <li nz-submenu nz-tooltip nzTooltipPlacement="right" [(nzOpen)]="openMap.settings" (nzOpenChange)="openHandler('settings')">
        <div title class="ant-menu-submenu-title-custom">
          <span class="icon" nz-icon nzType="setting" nzTheme="fill"></span>
          <span>{{ 'application-menu' | translate }}</span>
        </div>
        <ul>
          @for (menuItem of settingsLinks; track menuItem.defaultRoute) {
            @if (menuItem.sublinks?.length) {
              <li nz-submenu [nzOpen]="sublinkContainsCurrentUrl(menuItem.sublinks)">
                <a title>
                  @switch (menuItem.iconType) {
                    @case (iconTypeEnum.WAYTR) {
                      <span class="icon" [title]="menuItem.title">
                        <waytr-icon
                          [name]="menuItem.icon || 'food_menu'"
                          [title]="'icons.' + (menuItem.icon || 'food_menu') | translate"></waytr-icon>
                      </span>
                    }
                    @case (iconTypeEnum.NZ) {
                      <span
                        class="icon"
                        nz-icon
                        [nzType]="menuItem.icon || 'home'"
                        nzTheme="fill"
                        [title]="'icons.' + (menuItem.icon || 'home') | translate"></span>
                    }
                  }
                  <span class="page-title">{{ menuItem.translatePath | translate }}</span>
                </a>
                <ul>
                  @for (subLink of menuItem.sublinks; track $index) {
                    <li nz-menu-item nzMatchRouter>
                      <a [routerLink]="subLink.route" class="non-selectable" routerLinkActive="link-active">
                        @switch (subLink.iconType) {
                          @case (iconTypeEnum.WAYTR) {
                            <span class="icon" [title]="subLink.title">
                              <waytr-icon
                                [name]="subLink.icon || 'food_menu'"
                                [title]="'icons.' + (menuItem.icon || 'food_menu') | translate"></waytr-icon>
                            </span>
                          }
                          @case (iconTypeEnum.NZ) {
                            <span
                              class="icon"
                              nz-icon
                              [nzType]="subLink.icon || 'home'"
                              nzTheme="fill"
                              [title]="'icons.' + (menuItem.icon || 'home') | translate"></span>
                          }
                        }
                        <span class="page-title">{{ subLink.translatePath | translate }}</span>
                      </a>
                    </li>
                  }
                </ul>
              </li>
            } @else {
              <li nz-menu-item nzMatchRouter>
                <a [routerLink]="menuItem.route" class="non-selectable" routerLinkActive="link-active">
                  @switch (menuItem.iconType) {
                    @case (iconTypeEnum.WAYTR) {
                      <span class="icon" [title]="menuItem.title">
                        <waytr-icon
                          [name]="menuItem.icon || 'food_menu'"
                          [title]="'icons.' + (menuItem.icon || 'food_menu') | translate"></waytr-icon>
                      </span>
                    }
                    @case (iconTypeEnum.NZ) {
                      <span
                        class="icon"
                        nz-icon
                        [nzType]="menuItem.icon || 'home'"
                        nzTheme="fill"
                        [title]="'icons.' + (menuItem.icon || 'home') | translate"></span>
                    }
                  }
                  <span class="page-title">{{ menuItem.translatePath | translate }}</span>
                </a>
              </li>
            }
          }
        </ul>
      </li>
    </ul>
  }

  @defer {
    <div class="footer">
      <div class="logo">
        <img ngSrc="assets/img/app/logo.svg" fill priority [alt]="'waytr-logo' | translate" />
      </div>
      <p class="footer__title non-selectable">{{ appName }}</p>
    </div>
  }
</nz-sider>
