@defer {
  <img
    [@fadeAnimation]
    class="background non-selectable"
    ngSrc="assets/img/app/gradient.png"
    fill
    priority
    [alt]="'waytr-background' | translate" />
}
<aside class="navigation" [@fadeAnimation]>
  <app-side-menu [sidebarCollapsed]="isSidebarCollapsed"></app-side-menu>
</aside>
<span
  [@addAnimation]
  [@removeAnimation]
  nz-icon
  nzType="menu"
  nzTheme="outline"
  class="collapse-button clickable"
  [title]="isSidebarCollapsed ? ('open' | translate) : ('close' | translate) + ' sidebar'"
  tabindex="0"
  (click)="sidebarCollapsedClicked.set(!sidebarCollapsedClicked())"
  (keyup)="sidebarCollapsedClicked.set(!sidebarCollapsedClicked())"></span>
<main class="container">
  <app-main></app-main>
</main>

<ng-template #installPromptNotification>
  <div class="install-notification">
    <h4>{{ 'install-prompt-title' | translate }}</h4>
    <p>{{ 'install-prompt-body' | translate }}</p>
    <button nz-button nzType="primary" class="button-accent" (click)="promptInstall()">{{ 'install-waytr' | translate }}</button>
    <button nz-button nzType="text" (click)="removeNotification()">{{ 'no-thanks' | translate }}</button>
  </div>
</ng-template>
