import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAccountService } from '../services';

@Injectable()
/**
 * Interceptor that adds JWT token to the authorization header of outgoing HTTP requests.
 */
export class JwtInterceptor implements HttpInterceptor {
  readonly #userAccountService = inject(UserAccountService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentUser = this.#userAccountService.userJWT();
    const isLoggedIn = !!currentUser?.accessToken;
    const isApiUrl = request.url.startsWith(environment.API_URL);

    if (isLoggedIn && isApiUrl) {
      request = this.addAuthBearer(request, currentUser.accessToken);
    }

    return next.handle(request);
  }

  private addAuthBearer(request: HttpRequest<unknown>, accessToken: string): HttpRequest<unknown> {
    return request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
