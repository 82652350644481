import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  WritableSignal,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router, RoutesRecognized } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule, NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import { distinctUntilChanged } from 'rxjs';
import { addAnimation, fadeAnimation, removeAnimation, routerAnimation, slideRightAnimation } from './animations';
import { AppMainComponent } from './components/app-main/app-main.component';
import { AppSideMenuComponent } from './components/app-side-menu/app-side-menu.component';
import { LOCALE_KEY, POS, ROMANIAN, WAYTR } from './constants';
import { stopPropagation } from './helpers';
import { LocalStorageService, TitleService, UserAccountService } from './services';
import { LocalesService } from './services/locales.service';
import {
  WaytrIconsService,
  waytrAdmin,
  waytrAnalytics,
  waytrArrowDown,
  waytrArrowLeft,
  waytrArrowRight,
  waytrBowl,
  waytrBreakfast,
  waytrBurger,
  waytrBusy,
  waytrCard,
  waytrCart,
  waytrChecklist,
  waytrChicken,
  waytrClean,
  waytrCutlery,
  waytrDirectSale,
  waytrDiscount,
  waytrDrink,
  waytrEdit,
  waytrFoodMenu,
  waytrFresh,
  waytrHash,
  waytrLink,
  waytrLink2,
  waytrMushrooms,
  waytrNoodle,
  waytrNotification,
  waytrOrders,
  waytrPastry,
  waytrPizza,
  waytrPromoted,
  waytrSales,
  waytrSaltAndPepper,
  waytrSoup,
  waytrSplit,
  waytrStar,
  waytrSushi,
  waytrSweet,
  waytrSwitch,
  waytrTable,
  waytrTableManagement,
  waytrTaco,
  waytrTissue,
  waytrUpload,
  waytrWaiter,
  waytrWallet,
  waytrWeight,
} from './shared/components';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimation, addAnimation, removeAnimation, slideRightAnimation, fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    AppSideMenuComponent,
    AppMainComponent,
    NzIconModule,
    NzButtonModule,
    NzNotificationModule,
    NgOptimizedImage,
    TranslateModule,
  ],
})
export class AppComponent implements OnInit {
  @ViewChild('installPromptNotification', { static: false }) template?: TemplateRef<{}>;

  readonly #userAccountService = inject(UserAccountService);
  readonly #titleService = inject(TitleService);
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);
  readonly #notification = inject(NzNotificationService);

  protected Breakpoints = Breakpoints;
  protected stopPropagation = stopPropagation;

  private deferredPrompt: WritableSignal<any> = signal<any>(null);
  protected installPromptVisible: WritableSignal<boolean> = signal<boolean>(false);
  protected installPromptNotification: WritableSignal<NzNotificationRef | null> = signal<NzNotificationRef | null>(null);
  protected sidebarCollapsedClicked: WritableSignal<boolean> = signal<boolean>(false);
  protected hideSidebar: WritableSignal<boolean> = signal<boolean>(false);
  protected mediumBreakpoint: WritableSignal<boolean> = signal<boolean>(false);
  protected smallBreakpoint: WritableSignal<boolean> = signal<boolean>(false);
  protected xsBreakpoint: WritableSignal<boolean> = signal<boolean>(false);

  @HostBinding('class.app-not-loaded') get isAppNotLoaded() {
    return !this.#userAccountService.userDetails();
  }

  @HostBinding('class.hide-sidebar') get isSidebarHidden() {
    return this.hideSidebar();
  }

  @HostBinding('class.sidebar-collapsed') get isSidebarCollapsed() {
    return this.sidebarCollapsedClicked();
  }

  @HostBinding('class.medium') get isMediumBreakpoint() {
    return this.mediumBreakpoint();
  }

  @HostBinding('class.small') get isSmallBreakpoint() {
    return this.smallBreakpoint();
  }

  @HostBinding('class.xs') get isXsBreakpoint() {
    return this.xsBreakpoint();
  }

  readonly #breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(distinctUntilChanged());

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {
    // afterNextRender(() => {
    //   const notification = this.#notification.template(this.template!, {
    //     nzDuration: 300000,
    //     nzPlacement: 'bottomRight',
    //   });
    //   this.installPromptNotification.set(notification);
    // });

    if (isPlatformBrowser(platformId)) {
      if ('serviceWorker' in navigator) {
        // window.addEventListener('load', () => {
        //   navigator.serviceWorker.register('waytr-sw.js').catch(error => {
        //     console.error('Service worker registration failed:', error);
        //   });
        // });
        // Handle the `beforeinstallprompt` event
        // window.addEventListener('beforeinstallprompt', (event: Event) => {
        //   this.deferredPrompt.set(event); // Stash the event so it can be triggered later
        // });
      }
    }

    effect(() => {
      const notification = this.installPromptNotification();

      if (!notification) return;

      notification.onClose.subscribe(() => {
        this.installPromptVisible.set(false);
      });
    });
  }

  ngOnInit() {
    this.#breakpoint$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => this.breakpointChanged());
    this.#router.events.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const data = event.state.root.firstChild?.data;
        const titleTranslationKey: string = data?.['titleTranslationKey'];
        const hideSidebar: boolean = data?.['hideSidebar'];

        this.#titleService.changeToNewPage(this.#translateService.instant(titleTranslationKey));
        this.hideSidebar.set(hideSidebar);
      }
    });
  }

  protected removeNotification() {
    this.#notification.remove(this.installPromptNotification()?.messageId);
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.xsBreakpoint.set(true);
      this.smallBreakpoint.set(false);
      this.mediumBreakpoint.set(false);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.smallBreakpoint.set(true);
      this.xsBreakpoint.set(false);
      this.mediumBreakpoint.set(false);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.mediumBreakpoint.set(true);
      this.xsBreakpoint.set(false);
      this.smallBreakpoint.set(false);
    } else {
      this.xsBreakpoint.set(false);
      this.smallBreakpoint.set(false);
      this.mediumBreakpoint.set(false);
    }
  }

  // Trigger the install prompt
  promptInstall() {
    const deferredPrompt = this.deferredPrompt();

    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the native install prompt
      deferredPrompt.userChoice.then((choiceResult: any) => {
        this.deferredPrompt.set(null); // Clear the prompt event
      });
    }
  }
}

export function appInitializerFactory(
  titleService: TitleService,
  localesService: LocalesService,
  localStorageService: LocalStorageService,
  translateService: TranslateService,
  waytrIconsService: WaytrIconsService,
  platformId: Object,
): () => Promise<void> {
  return () => {
    return new Promise<void>(async resolve => {
      waytrIconsService.addIcons([
        waytrFresh,
        waytrBowl,
        waytrCart,
        waytrChicken,
        waytrCutlery,
        waytrMushrooms,
        waytrPastry,
        waytrPizza,
        waytrSweet,
        waytrOrders,
        waytrFoodMenu,
        waytrCart,
        waytrTableManagement,
        waytrWaiter,
        waytrDirectSale,
        waytrTable,
        waytrAnalytics,
        waytrNotification,
        waytrBusy,
        waytrStar,
        waytrSales,
        waytrHash,
        waytrLink,
        waytrCard,
        waytrUpload,
        waytrEdit,
        waytrLink2,
        waytrTaco,
        waytrNoodle,
        waytrBreakfast,
        waytrSoup,
        waytrSushi,
        waytrBurger,
        waytrDrink,
        waytrSaltAndPepper,
        waytrClean,
        waytrTissue,
        waytrArrowLeft,
        waytrArrowRight,
        waytrDiscount,
        waytrSplit,
        waytrChecklist,
        waytrArrowDown,
        waytrSwitch,
        waytrWallet,
        waytrWeight,
        waytrPromoted,
        waytrAdmin,
      ]);

      if (isPlatformBrowser(platformId)) {
        const locale = (await localStorageService.getData<string>(LOCALE_KEY)) || ROMANIAN;
        titleService.loadInitialTitle(`${WAYTR} ${POS}`);
        translateService.setDefaultLang(locale);
        localesService.setLocale(locale);
        localesService.getAllLocales().then(locales => {
          localesService.setAllLocales(locales);
          resolve();
        });
      } else {
        resolve();
      }
    });
  };
}
