import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AUTH_PATH } from '../constants';
import { IRefreshToken } from '../interfaces';
import { JwtModel } from '../models';
import { AuthenticationService, UserAccountService } from '../services';

@Injectable()
/**
 * Interceptor that adds JWT token to the authorization header of outgoing HTTP requests.
 */
export class RefreshTokenInterceptor implements HttpInterceptor {
  readonly #userAccountService = inject(UserAccountService);
  readonly #authenticationService = inject(AuthenticationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const refreshTokenUrl = request.url.endsWith(`/${AUTH_PATH}/refresh`);

    return next.handle(request).pipe(
      catchError(error => {
        if (refreshTokenUrl && error instanceof HttpErrorResponse && [500, 401, 404].includes(error.status)) {
          // If the error is due to an expired token, log the user out
          this.#authenticationService.logout();
          return next.handle(request);
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleUnauthorizedError(request, next);
        }

        return throwError(() => error);
      }),
    );
  }

  private handleUnauthorizedError(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const refreshTokenObj: IRefreshToken = { refreshToken: this.#userAccountService.userJWT()?.refreshToken || '' };

    const currentUser = this.#userAccountService.userJWT();
    const isLoggedIn = !!currentUser?.refreshToken && !!currentUser?.accessToken;

    if (!isLoggedIn) {
      return next.handle(request);
    }

    return this.#authenticationService.refreshToken(refreshTokenObj).pipe(
      switchMap((newJWTsObj: JwtModel) => {
        const updatedToken: JwtModel = { accessToken: newJWTsObj.accessToken, refreshToken: newJWTsObj.refreshToken };
        this.#userAccountService.setUserAccount(updatedToken);

        request = this.addAuthBearer(request, updatedToken.accessToken);
        return next.handle(request);
      }),
      catchError(error => {
        const isApiUrl = request.url.startsWith(environment.API_URL);
        const refreshTokenUrl = request.url.endsWith(`/${AUTH_PATH}/refresh`);

        if (isApiUrl && error instanceof HttpErrorResponse && error.status === 500) {
          // Call the method to refresh the token

          if (refreshTokenUrl) {
            // If the error is due to an expired token, log the user out
            this.#authenticationService.logout();
            return next.handle(request);
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private addAuthBearer(request: HttpRequest<unknown>, accessToken: string): HttpRequest<unknown> {
    request = request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return request;
  }
}
